/*
 *  Copyright (C) Healabs 2022 - All Rights Reserved
 *  Unauthorized copying of this file, via any medium is strictly prohibited
 *  Proprietary and confidential
 *
 */

import crud from '@/services/crud'

function denormalize(admission) {
  if (admission && typeof admission.patientIdentity !== 'undefined') {
    admission.patient = admission.patientIdentity
    delete admission.patientIdentity
  }
  return admission
}

let {get: doGet, create: doCreate, update: doUpdate} = crud('/admissions')

export async function get(id, config) {
  let response = await doGet(id, config)
  response.data = denormalize(response.data)
  return response
}

export async function create(data, config) {
  let response = await doCreate(data, config)
  response.data = denormalize(response.data)
  return response
}

export async function update(id, data, partial = false, config = {}) {
  let response = await doUpdate(id, data, partial, config)
  response.data = denormalize(response.data)
  return response
}
