<!--
  -  Copyright (C) Healabs 2022 - All Rights Reserved
  -  Unauthorized copying of this file, via any medium is strictly prohibited
  -  Proprietary and confidential
  -
  -->

<template>
  <v-menu v-model="menu"
          :close-on-content-click="false"
          :nudge-right="40"
          offset-y
          max-width="290px"
          min-width="290px"
  >
    <template v-slot:activator="{on , attrs }">
      <text-field v-model="localValue" clearable readonly v-bind="{...attrs, ...$attrs}" v-on="{...on, $listeners}" />
    </template>
    <v-time-picker v-if="menu" v-model="localValue" full-width format="24hr" @click:minute="menu = false" />
  </v-menu>
</template>

<script>
  import TextField from '@/modules/core/components/TextField'
  export default {
    name: 'TimePicker',
    components: {TextField},
    props: {
      value: String
    },
    data() {
      return {
        menu: false,
        selecting: 1
      }
    },
    computed: {
      localValue: {
        get() {
          return this.value
        },
        set(value) {
          this.$emit('input', value)
        }
      }
    }
  }
</script>

