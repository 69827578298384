/*
 *  Copyright (C) Healabs 2022 - All Rights Reserved
 *  Unauthorized copying of this file, via any medium is strictly prohibited
 *  Proprietary and confidential
 *
 */

import { rules } from '@/services/rules'
import { clean, deepCopy, pick } from '@/utils/object'
import { empty } from '@/utils/variables'
import dayjs from 'dayjs'
import { createModelForm } from '@/services/model'

const MODEL = {
  lastName: {
    type: 'text',
    label: 'Nom usuel',
    rules: [rules.required, rules.maxLength(200), rules.minLength(1)]
  },
  birthName: {
    type: 'text',
    label: 'Nom de naissance'
  },
  firstName: {
    type: 'text',
    label: 'Prénom',
    rules: [rules.required, rules.maxLength(200), rules.minLength(1)]
  },
  gender: {
    type: 'choice',
    label: 'Sexe',
    rules: [rules.required],
    iconRequired: true,
    options: [
      {text: '', value: 'U'},
      {text: 'Homme', value: 'M'},
      {text: 'Femme', value: 'F'}
    ]
  },
  birthDate: {
    type: 'birthdate',
    label: 'Date de naissance',
    rules: [rules.required]
  },
  phone: {
    type: 'phone',
    label: 'Téléphone fixe',
    rules: [rules.phone],
    errorIcon: false
  },
  mobilePhone: {
    type: 'phone',
    label: 'Téléphone portable',
    rules: [rules.phone],
    errorIcon: false
  },
  email: {
    type: 'email',
    label: 'E-mail',
    rules: [rules.required, rules.email, rules.maxLength(200)]
  },
  address: {
    type: 'form',
    children: {
      street: {
        type: 'text',
        label: 'Adresse',
        rules: [rules.maxLength(200)],
        errorIcon: false
      },
      country: {
        type: 'text',
        label: 'Pays',
        rules: [rules.maxLength(200)],
        errorIcon: false
      },
      city: {
        type: 'text',
        label: 'Ville',
        rules: [rules.maxLength(200)],
        errorIcon: false
      },
      zipCode: {
        type: 'zipcode',
        label: 'Code postal',
        rules: [rules.digit, rules.maxLength(200)],
        errorIcon: false
      }
    }
  }
}

export default MODEL

// vue to api format
export function normalize(data) {
  let result = deepCopy(data)
  result.lastName = empty(data.lastName) ? data.birthName : data.lastName
  result.birthName = empty(data.birthName) ? data.lastName : data.birthName
  result.gender = empty(data.gender) ? 'U' : data.gender

  return clean(result, null)
}

export function denormalize(data) {
  let result = {
    ...deepCopy(createModelForm(MODEL)),
    ...pick(data, ['id', 'gender', 'firstName', 'lastName', 'birthName', 'phone', 'mobilePhone', 'email']),
    birthDate: dayjs(data.birthDate).format('YYYY-MM-DD')
  }

  if (!empty(data.address)) {
    result.address = pick(data.address, ['@id', 'street', 'zipCode', 'city', 'country'])
  }

  return result
}
