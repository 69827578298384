<!--
  -  Copyright (C) Healabs 2022 - All Rights Reserved
  -  Unauthorized copying of this file, via any medium is strictly prohibited
  -  Proprietary and confidential
  -
  -->

<template>
  <field-list v-model="localValue" class="pt-5" :children="fields" />
</template>

<script>
  import fields from '@/models/patient'
  import FieldList from '@/modules/core/components/FieldList'

  export default {
    name: 'PatientInformationFields',
    components: {FieldList},
    props: {
      value: Object
    },
    data() {
      return {
        fields,
        date: new Date().toISOString().substr(0, 10),
        sexOptions: [
          {text: 'Homme', value: 'M'},
          {text: 'Femme', value: 'F'}
        ]
      }
    },
    computed: {
      localValue: {
        get() {
          return this.value
        },
        set(value) {
          this.$emit('input', value)
        }
      }
    }
  }
</script>
