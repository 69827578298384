<!--
  -  Copyright (C) Healabs 2022 - All Rights Reserved
  -  Unauthorized copying of this file, via any medium is strictly prohibited
  -  Proprietary and confidential
  -
  -->

<template>
  <component :is="component">
    <template v-for="(field, key) in children">
      <template v-if="field.type === 'form'">
        <field-list :key="key" v-model="localValue[key]" :children="field.children" inline />
      </template>
      <template v-else>
        <v-col :key="key" cols="12" md="6">
          <field :key="key" v-model="localValue[key]" :field="field" />
        </v-col>
      </template>
    </template>
  </component>
</template>

<script>
  import Field from '@/modules/core/components/Field'
  import { Fragment } from 'vue-fragment'
  import { VRow } from 'vuetify/lib/components'

  export default {
    name: 'FieldList',
    components: {Field},
    props: {
      children: Object,
      value: Object,
      inline: Boolean
    },
    computed: {
      component() {
        return this.inline ? Fragment : VRow
      },
      localValue: {
        get() {
          return this.value
        },
        set(value) {
          this.$emit('input', value)
        }
      }
    }
  }
</script>

<style scoped>

</style>
