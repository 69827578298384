/*
 *  Copyright (C) Healabs 2022 - All Rights Reserved
 *  Unauthorized copying of this file, via any medium is strictly prohibited
 *  Proprietary and confidential
 *
 */

export function createModelForm(model) {
  let form = {}
  for (let key in model) {
    let property = model[key]
    let value
    switch (property.type) {
      case 'form':
          value = createModelForm(property.children)
        break
      default:
        value = ''
    }
    form[key] = value
  }
  return form
}
