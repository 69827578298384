<!--
  -  Copyright (C) Healabs 2022 - All Rights Reserved
  -  Unauthorized copying of this file, via any medium is strictly prohibited
  -  Proprietary and confidential
  -
  -->

<template>
  <text-field v-if="field.type === 'zipcode'" v-model="localValue"
              :label="field.label" :rules="field.rules" :error-icon="field.errorIcon"
  />
  <text-field v-else-if="field.type === 'phone'" v-model="localValue"
              :label="field.label" :rules="field.rules" :error-icon="field.errorIcon"
  />
  <text-field v-else-if="field.type === 'text'" v-model="localValue"
              :label="field.label" :rules="field.rules" :error-icon="field.errorIcon"
  />
  <text-field v-else-if="field.type === 'email'" v-model="localValue"
              :label="field.label" :rules="field.rules" :error-icon="field.errorIcon"
              validate-on-blur type="email"
  />
  <v-select v-else-if="field.type === 'choice'" v-model="localValue"
            :label="field.label" :items="field.options" :rules="field.rules" :multiple="field.multiple"
            color="secondary" clearable outlined dense hide-details="auto"
  >
    <template v-if="field.iconRequired" v-slot:append>
      <v-icon small :color="localValue ? 'success' : 'red lighten-3'" class="pt-2">$warning</v-icon>
    </template>
  </v-select>
  <date-picker v-else-if="field.type === 'date' || field.type === 'birthdate'" v-model="localValue"
               :birth-date="field.type === 'birthdate'" :label="field.label" :rules="field.rules"
  />
</template>

<script>
  import DatePicker from '@/modules/core/components/DatePicker'
  import TextField from '@/modules/core/components/TextField'

  export default {
    name: 'Field',
    components: {TextField, DatePicker},
    props: {
      field: {
        type: Object,
        required: true
      },
      value: {
        required: true
      }
    },
    computed: {
      localValue: {
        get() {
          return this.value
        },
        set(value) {
          value = (this.field.type === 'email') ? value.toLowerCase() : value
          this.$emit('input', value)
        }
      }
    }
  }
</script>
