<!--
  -  Copyright (C) Healabs 2022 - All Rights Reserved
  -  Unauthorized copying of this file, via any medium is strictly prohibited
  -  Proprietary and confidential
  -
  -->

<template>
  <v-card v-if="!loading" class="elevation-0 py-3">
    <v-form ref="form" lazy-validation @submit.prevent="submit">
      <v-card-title class="pt-0 font-alt">Nouvelle admission</v-card-title>
      <v-card-subtitle class="font-weight-light text-caption font-alt">
        Création d'une nouvelle admission
      </v-card-subtitle>
      <div class="splitter" />
      <v-card-title class="pb-2 font-alt text-subtitle-1 primary--text">Informations de l'admission</v-card-title>
      <v-card-subtitle class="font-weight-light text-caption font-alt secondary--text">
        Veuillez renseigner les informations de l'admission
      </v-card-subtitle>
      <v-row class="px-4">
        <v-col cols="12" md="6">
          <v-select v-model="model.establishment"
                    label="Lieu de l'admission"
                    color="secondary" outlined dense hide-details="auto"
                    :items="[currentEstablishment]"
                    item-text="label"
                    item-value="id"
                    :rules="[$rules.required]"
          >
            <template v-slot:append>
              <v-icon small :color="model.establishment ? 'success' : 'red lighten-3'" class="pt-2">$warning</v-icon>
            </template>
          </v-select>
        </v-col>
        <v-col cols="12" md="6">
          <v-select v-model="model.stay.service"
                    label="Service"
                    color="secondary" outlined dense hide-details="auto"
                    :items="services"
                    item-text="label"
                    item-value="@id"
                    :disabled="services.length===0"
                    :loading="servicesLoading"
                    :rules="[$rules.required]"
                    @change="loadDoctors"
          >
            <template v-slot:append>
              <v-icon small :color="model.stay.service ? 'success' : 'red lighten-3'" class="pt-2">$warning</v-icon>
            </template>
          </v-select>
        </v-col>
        <v-col cols="12" md="6">
          <v-select v-model="model.stay.type"
                    type="text" label="Type d'admission"
                    color="secondary" outlined dense hide-details="auto"
                    :items="types"
                    :rules="[$rules.required]"
          >
            <template v-slot:append>
              <v-icon small :color="model.stay.type ? 'success' : 'red lighten-3'" class="pt-2">$warning</v-icon>
            </template>
          </v-select>
        </v-col>
        <v-col cols="12" md="6">
          <date-picker v-model="model.stay.admissionDate" label="Date d'admission" :rules="[$rules.required]" />
        </v-col>
        <v-col cols="12" md="6">
          <time-picker v-model="model.stay.admissionTime" label="Heure d'admission" :rules="[$rules.required]" />
        </v-col>
        <v-col cols="12" md="6">
          <v-select v-model="model.stay.doctor" type="text" label="Médecin responsable de l'hospitalisation"
                    color="secondary" outlined dense hide-details="auto"
                    :items="doctorItems"
          />
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field v-model="model.stay.number" type="text" label="Numéro de séjour" :rules="[$rules.required, $rules.maxLength(200)]"
                        color="secondary" outlined dense hide-details="auto"
          >
            <template v-slot:append>
              <v-icon small :color="model.stay.number ? 'success' : 'red lighten-3'" class="pt-2">$warning</v-icon>
            </template>
          </v-text-field>
        </v-col>
      </v-row>

      <v-card-title class="pb-2 font-alt text-subtitle-1 primary--text mt-8">Informations patient</v-card-title>
      <v-card-subtitle class="font-weight-light text-caption font-alt secondary--text">Veuillez renseigner les informations du patient</v-card-subtitle>

      <patient-information-fields v-model="model.patient" class="px-4" />

      <v-card-actions class="d-flex justify-end mt-5 mx-3">
        <v-btn type="submit" small color="secondary" :disabled="submitting" :loading="submitting">
          Créer l'admission
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
  import api from '@/services/api'
  import * as admissions from '@/modules/stays/api/admissions'
  import * as establishments from '@/modules/establishments/api'
  import * as doctors from '@/modules/professionals/api/doctors'
  import PatientInformationFields from '@/modules/patients/components/form/PatientInformationFields'
  import patientModel, {normalize as normalizePatient} from '@/models/patient'
  import stayModel, {normalize as normalizeStay} from '@/modules/stays/model'
  import DatePicker from '@/modules/core/components/DatePicker'
  import TimePicker from '@/modules/core/components/TimePicker'
  import {getIdFromIri} from '@/utils/jsonld'
  import { createModelForm } from '@/services/model'

  export default {
    name: 'AdmissionForm',
    components: {TimePicker, DatePicker, PatientInformationFields},
    data() {
      return {
        loading: true,
        submitting: false,
        currentEstablishment: null,
        services: [],
        servicesLoading: false,
        doctors: [],
        types: [
          {text: 'Hospitalisation', value: 'hospitalisation'},
          {text: 'Ambulatoire', value: 'ambulatory'}
        ],
        model: {
          establishment: null,
          stay: {...stayModel},
          patient: createModelForm(patientModel)
        }
      }
    },
    computed: {
      doctorItems() {
        return this.doctors ? this.doctors.map(doctor => {
          return {
            text: 'Dr. ' + doctor.firstname + ' ' + doctor.lastname,
            value: doctor['@id']
          }
        }) : []
      }
    },
    watch: {
      async ['model.establishment'](value) {
        if (!value) {
          this.services = null
          this.model.service = null
        } else {
          try {
            this.servicesLoading = true
            this.services = (await establishments.services.list({
              params: {
                establishment: value
              }
            })).data['hydra:member']
          } catch (error) {
            this.$root.$emit('error', error)
          } finally {
            this.servicesLoading = false
          }
        }
      }
    },
    async created() {
      await this.loadEstablishments()
      this.loading = false
    },
    methods: {
      async loadEstablishments(){
        try {
          this.currentEstablishment = (await api.get(this.$auth.user.identity.professionalIdentityEstablishments[0].establishment['@id'])).data
        } catch (error) {
          this.$root.$emit('error', error)
        }
      },
      async loadDoctors(iriService) {
        let params = {
          services: getIdFromIri(iriService)
        }
        this.doctors = (await doctors.list({params})).data['hydra:member']
      },
      async submit() {
        if (!this.$refs.form.validate()) {
          return false
        }
        try {
          this.submitting = true

          let {data} = await admissions.create({
            ...normalizeStay(this.model.stay),
            patient: normalizePatient(this.model.patient)
          })
          this.$root.$emit('snackbar:open', {
            snackbar: ()=> import('@/modules/core/components/Snackbar'),
            type: 'success',
            title: 'Admission créée avec succès'
          })
          await this.$router.push('/admissions/admission-card/'+data.id)
        } catch (error) {
          this.$root.$emit('snackbar:open', {
            snackbar: ()=> import('@/modules/core/components/Snackbar'),
            type: 'error',
            title: 'Une erreur est survenue'
          })
        } finally {
          this.submitting = false
        }
      }
    }
  }
</script>
