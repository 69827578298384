/*
 *  Copyright (C) Healabs 2022 - All Rights Reserved
 *  Unauthorized copying of this file, via any medium is strictly prohibited
 *  Proprietary and confidential
 *
 */

import dayjs from 'dayjs'
import { clean, pick } from '@/utils/object'

export default {
  service: null,
  type: null,
  doctor: null,
  number: null,
  admissionDate: null,
  admissionTime: null,
  status: 'in_progress'
}

// vue to api format
export function normalize(data) {
  return clean({
    ...pick(data, ['type', 'patient', 'service', 'doctor', 'number', 'status']),
    startDate: data.admissionTime ? dayjs(data.admissionDate + ' ' + data.admissionTime).utc().toISOString() : null,
    number: data.number
  })
}
